import request from '@/api/http'

/**
 * 音频库分类
 * @param payload
 */
export function group_list(payload) {
    return request({ url: '/storage/Group/lists', method: 'post', data: payload })
}

/**
 * 音频列表
 * @param payload
 */
export function audio(payload) {
    return request({ url: '/storage/Index/audio', method: 'post', data: payload })
}

/**
 * 上传音频
 * @param payload
 */
export let upload = process.env.VUE_APP_API_BASE_URL + '/storage/Upload/audio';